<template>
  <section
    id="services"
    class="overflow-hidden"
  >
    <v-row
      class="accent"
      no-gutters
    >
      <v-col
        class="hidden-sm-and-down"
        md="6"
      >
        <v-img
          :src="require('@/assets/gerhard_2.jpg')"
          height="100%"
        />
      </v-col>

      <v-col
        class="text-center pa-5"
        cols="12"
        md="6"
      >
        <v-row
          class="fill-height"
          align="center"
          justify="center"
        >
          <v-col cols="12">
            <base-heading class="white--text">
              {{ $t("services.title") }}
            </base-heading>
            <v-timeline>
              <v-timeline-item
                  v-for="(item, i) in services"
                  :key="i"
                  color="primary"
                  :icon="item.icon"
                  fill-dot
              >
                <v-card
                    color="primary"
                    dark
                >
                  <v-card-title class="text-subtitle-2">
                    {{ $t(`services.${item.date}`) }}
                  </v-card-title>
                  <v-card-text class="white text--primary pa-1">
                    <p class="pa-0 pt-1">{{ $t(`services.${item.blurb}`) }}</p>
                    <p class="pa-0 pt-1" v-if="$vuetify.breakpoint.mdAndUp && item.blurb === 'ktt.text'">{{ $t(`services.${item.text}`) }}</p>
                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </section>
</template>

<script>
  export default {
    name: 'Services',

    data: () => ({
      services: [
        {
          date: 'birth.date',
          icon: 'mdi-baby-face-outline',
          blurb: 'birth.text',
        },
        {
          date: 'school.date',
          icon: 'mdi-school-outline',
          blurb: 'school.text',
        },
        {
          date: 'school1.date',
          icon: 'mdi-school-outline',
          blurb: 'school1.text',
        },
        {
          date: 'school2.date',
          icon: 'mdi-school-outline',
          blurb: 'school2.text',
        },
        {
          date: 'school3.date',
          icon: 'mdi-school-outline',
          blurb: 'school3.text',
        },
        {
          date: 'business.date',
          icon: 'mdi-handshake',
          blurb: 'business.text',
        },
        {
          date: 'school4.date',
          icon: 'mdi-school-outline',
          blurb: 'school4.text',
        },
        {
          date: 'ktt.date',
          icon: 'mdi-handshake',
          blurb: 'ktt.text',
          text: 'ktt.text1'
        },
      ],
    }),
  }
</script>
